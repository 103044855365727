<template>
   <div class="container-fluid">


        <section class="content-header">
                <div class="row mb-2">
                    <h3>{{ $t('general.listadocomerciales') }}</h3>
                </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                    <comerciales-estados />
                </div>
                 <div class="col-md-10">
                             <tabla-datos 
                    :columnas="columnas" 
                    :datos="comerciales"
                    :filtros="filtros" 
                    :filtrosBuscador="filtrosBuscador" 
                    :total="total"
                    @quieroDatos="obtenerComerciales" 
                ></tabla-datos>
        </div>
            </div>
        </section>
    </div>
</template>
<script>
import ComercialesEstados from './listado/ComercialesEstados.vue';
import TablaDatos from '../../TablaDatos.vue';
import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {
        TablaDatos,
        'comerciales-estados': ComercialesEstados,
    },
    data() {
        return {
            comerciales: [],
            parametros: null, 
            filtrosBuscador: ['nombre', 'ciudad','email'], // filtros para el buscador
            filtros: { // filtros de columna
                'nombre': { value: '', matchMode: 'contains' },
                'ciudad': { value: '', matchMode: 'contains' },
                'email': { value: '', matchMode: 'contains' },
                
            },
            columnas: [
                {
                    header: 'Nombre',
                    field: 'nombre_comercial',
                    sortable: true,
                },
                {
                    header: 'Ciudad',
                    field: 'ciudad_comercial',
                    sortable: true,
                },
                {
                    header: 'Teléfono',
                    field: 'telefono_comercial',
                },
                {
                    header: 'Email',
                    field: 'email_comercial',
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_comercial',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Editar comercial ' + id);
                                this.$router.push({
                                    name: 'comercial',
                                    params: {
                                        id:id
                                    }
                                });
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_comercial',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar comercial ' + id);
                                if (confirm('Desea eliminar al comercial')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    methods: {
        async obtenerComerciales(parametros) {
            /* get  modulos/pwgsapi/index.php/comerciales*/
            const api = new PwgsApi();
            const params = parametros;
            const comerciales = await api.get('comerciales', params);
            this.comerciales = comerciales.datos;
            this.total = comerciales.n_total_registros;
            this.columnas[0].header = this.$t("general.nombre");
            this.columnas[1].header = this.$t("general.ciudad");
            this.columnas[2].header = this.$t("general.telefono");
            this.columnas[4].header = this.$t("general.acciones");
            

        },
        async eliminar(id) {
            /* delete  modulos/pwgsapi/index.php/comerciales/:id */
            const api = new PwgsApi();
            await api.delete('comerciales/' + id);
            this.obtenerComerciales();
        },
    },
    activated() {
        this.obtenerComerciales(this.parametros);
    }
}
</script>

<style>
.p-dialog {
    width: 90%;
}
</style>